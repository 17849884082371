.App {
  text-align: center;
  background-color: #f6f6f6;
}

.modal {
  transition: opacity 0.25s ease;
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}