html,
body {
  width: 100%;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
}

.bg-positive {
  background-color: #02b5ad;
}

.bg-positive:hover {
  background-color: #02a8a1;
}

#root {
  height: 100%;
}

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fna-color {
  background-color: #27316C;
  color: #fff;
}

.fna-color-secoundary {
  background-color: #F7C900;
  color: #fff;
}

.fna-color:hover {
  background-color: #222b5e;
}

.timer {
  border-radius: 50%;
  width: 30px;
  height: 20px;
  padding: 2px 5px;
}

.text-fna {
  color: #27316C;
  color: rgb(39, 49, 108, 1);
}

.stroke-fna {
  stroke: #27316C;
}