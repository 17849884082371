.stepper-container {
    display: flex;
    flex-wrap: nowrap;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .stepper-item {
        position: relative;
        // cursor: pointer;

        .stepper-item-outer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .stepper-title {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            position: absolute;
            display: inline-block;
            width: max-content;
            max-width: 200px;
            // min-width: 200px;
            transform: translate(-39%, 5px);
            text-align: center;
            color: #BFC2C3;
            font-size: .875rem;
        }

        .stepper-title-active {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            color: #000000;
        }
    }

}

.stepper-item-outer:nth-of-type(odd) {
    border: 2px solid #C8CACB;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    .stepper-item-inner {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        color: white;
        font-size: small;
    }

    .stepper-item-inner.stepper-item-inner-active {
        width: 18px;
        height: 18px;
    }
}

.stepper-item-outer:nth-of-type(even) {
    height: 0px;
    border: 0.5px solid #C8CACB;
    width: 15%;
}

.stepper-item-inner-future {
    background-color: #DEDFE0;
}

.stepper-item-inner-completed {
    background-color: #3b4ba5;
}

.stepper-item-inner-active {
    background-color: #27316C;
}